<template>
  <div>
    <select-medico-especialidade
      vertical
      lockMedico
      :medico_id="solicitacao.medico_id"
      @medico="solicitacao.medico_id = $event"
      @especialidade="solicitacao.especialidade_id = $event"
      :especialidade_id="solicitacao.especialidade_id"
      :disabledEspecialidade="!!solicitacao.respostas.id"
      :disabledMedico="!!solicitacao.respostas.id"
    ></select-medico-especialidade>
    <div class="row">
      <div class="col-12">
        <!-- <label>Especialidade</label>
        <v-select
          :options="especialidades"
          v-model="solicitacao.especialidade_id"
          :reduce="(espec) => espec.id"
          label="especialidade"
          @input="carregaMedicos"
          :disabled="!!solicitacao.respostas.id"
        >
          <template #search="{ attributes, events }">
            <input
              :class="['vs__search rounded']"
              v-bind="attributes"
              v-on="events"
            />
          </template>
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              Nenhum resultado para <em>{{ search }}</em
              >.
            </template>
            <em style="opacity: 0.5" v-else
              >Não encontramos nenhuma especialiade 😕.</em
            >
          </template>
        </v-select> -->
      </div>
      <div class="col-12">
        <!-- <label>Médico</label>

        <v-select
          :options="medicos"
          :reduce="(med) => med.id"
          v-model="solicitacao.medico_id"
          label="razao_social"
          :disabled="!!solicitacao.respostas.id"
        >
          <template #search="{ attributes, events }">
            <input
              :class="['vs__search rounded']"
              v-bind="attributes"
              v-on="events"
            />
          </template>
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              Nenhum resultado para <em>{{ search }}</em
              >.
            </template>
            <em style="opacity: 0.5" v-else
              >Não encontramos nenhum médico para está especialiade 😕.</em
            >
          </template>
        </v-select> -->
      </div>
      <div class="col-12">
        <label>Modelo de Anamnese</label>
        <v-select
          v-model="solicitacao.anamnese_id"
          :reduce="(mod) => mod.id"
          :disabled="!!solicitacao.respostas.id"
          :options="modelos"
          label="nome"
        >
          <template #search="{ attributes, events }">
            <input
              :class="['vs__search rounded']"
              v-bind="attributes"
              v-on="events"
            />
          </template>
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              Nenhum resultado para <em>{{ search }}</em
              >.
            </template>
            <em style="opacity: 0.5" v-else
              >Não encontramos nenhum modelo de anamnese 😕.</em
            >
          </template></v-select
        >
      </div>

      <div class="col-12 d-flex mt-3" v-if="solicitacao.id">
        <b-btn
          variant="info"
          @click="openPreencherAnamnese"
          class="text-nowrap flex-grow-1 mx-1 float-left rounded "
        >
          <i class="fas fa-notes-medical"></i>
          {{ solicitacao.respostas.id ? "Visualizar" : "Preencher" }} Anamnese
        </b-btn>
      </div>
      <div class="float-left text-danger p-3" v-if="hasChanged">
        <b-icon-info-circle /> Alterações realizadas! Não se esqueça de salvar!
      </div>
      <div class="col-12 mt-3 text-right border-top pt-3">
        <b-button variant="primary" class="mr-3" @click="salvar"
          ><i
            :class="[
              { 'fa fa-plus-circle': !solicitacao.id },
              { 'fa fa-edit': solicitacao.id },
            ]"
          />
          {{ solicitacao.id ? "Atualizar" : "Solicitar" }}</b-button
        >
        <b-button variant="secondary" @click="close"
          ><i class="fa fa-ban" /> Cancelar</b-button
        >
      </div>
    </div>

    <b-modal id="modal-anamnese" hide-footer size="lg">
      <template #modal-title>
        <div>
          <i class="fas fa-notes-medical"></i>
          {{ solicitacao.respostas.id ? "Visualizar" : "Preencher" }} Anamnese
        </div>
      </template>

      <anamnese-listagem
        @salvou="onAnamnese($event)"
        @close="closeAnamnese"
        :paciente="{ nome: paciente.razao_social, idade: paciente.idade }"
        :id="solicitacao.anamnese_id"
        :questionarioPreenchido="solicitacao.respostas"
          :responsavel="{
            nome: solicitacao.usuario_anamnese,
            data: solicitacao.dt_respondeu_anamnese,
          }"
      />
    </b-modal>
  </div>
</template>

<script>
import PacienteAnamnesesLib from "../../../../libs/PacienteAnamnesesLib";
import AnamneseLib from "../../../../libs/AnamneseLib";
import EntidadesLib from "../../../../libs/EntidadesLib";
import EspecialidadesLib from "../../../../libs/EspecialidadesLib";
import AnamneseListagem from "../../../Anamnese/Listagem/AnamneseListagem.vue";
import SelectMedicoEspecialidade from "../../../common/SelectMedicoEspecialidade.vue";

export default {
  components: {
    AnamneseListagem,
    SelectMedicoEspecialidade,
  },
  props: {
    paciente: Object,
    anamnese_edit: Object,
  },

  data() {
    return {
      especialidades: [],
      medicos: [],
      modelos: [],
      hasChanged: false,
      solicitacao: {
        paciente_id: this.paciente.id,
        medico_id: "",
        anamnese_id: "",
        especialidade_id: "",
        respostas: {},
      },
      loaded: false,
      loading: { campo: "", status: false },
    };
  },
  methods: {
    async carregaEspecialidades() {
      try {
        this.especialidades = await EspecialidadesLib.get();
      } catch (error) {
        console.log(error);
        this.showToast("error", "Erro ao carregar especialidades");
      }
    },
    openPreencherAnamnese() {
      this.$bvModal.show("modal-anamnese");
    },
    closeAnamnese() {
      this.$bvModal.hide("modal-anamnese");
    },
    close() {
      this.$emit("close");
    },
    onAnamnese(anamnese) {
      this.$forceUpdate();
      // console.log('recebeu anamnese?', anamnese);
      this.closeAnamnese();
      Object.assign(this.solicitacao.respostas, anamnese);
    },
    async carregaMedicos() {
      try {
        if (this.solicitacao.especialidade_id) {
          this.medicos = (await EntidadesLib.get("medico")).entidades.filter(
            (med) => {
              return med.configuracoes.especialidades.includes(
                this.solicitacao.especialidade_id
              );
            }
          );
        }
      } catch (error) {
        console.log(error);
        this.showToast("error", "Erro ao carregar médicos");
      }
    },
    async carregaModelos() {
      try {
        this.modelos = await AnamneseLib.get();
      } catch (error) {
        console.log(error);
        this.showToast("error", "Erro ao carregar modelos de anamnese");
      }
    },
    // onChangeModelo(id_anamnese) {
    //   if (!isNaN(id_anamnese)) {
    //     this.solicitacao.respostas = this.modelos.find(
    //       (mod) => mod.id == id_anamnese
    //     ).questionario;
    //   }
    // },
    validar() {
      let validacao = { validou: true, msg: [] };
      if (this.solicitacao.especialidade_id == "") {
        validacao.validou = false;
        validacao.msg.push("Por favor, selecione a especialidade!");
      }
      if (this.solicitacao.medico_id == "") {
        validacao.validou = false;
        validacao.msg.push("Por favor, selecione o médico!");
      }
      if (this.solicitacao.anamnese_id == "") {
        validacao.validou = false;
        validacao.msg.push("Por favor, selecione o modelo da anamnese!");
      }
      // if (this.solicitacao.respostas.length == 0) {
      //   validacao.validou = false;
      //   validacao.msg.push(
      //     "O modelo selecionado está com algum problema, por favor, verifique se ele foi cadastrado corretamente!"
      //   );
      // }

      return validacao;
    },
    async salvar() {
      let valida = this.validar();
      // console.log("validou?", valida);
      // console.log("solicitacao:", this.solicitacao);
      if (valida.validou) {
        try {
          let result = await PacienteAnamnesesLib.store(this.solicitacao);
          if (result.status == 201 || result.status == 200) {
            this.showToast("success", "Solicitação feita com sucesso!");
            this.$emit("salvou");
          }
        } catch (error) {
          this.showToast("error", error);
        }
      } else {
        this.$swal("Erro", ...valida.msg, "error");
      }
    },
  },
  async mounted() {
    if (!isNaN(this.anamnese_edit.id)) {
      this.solicitacao = Object.assign({}, this.anamnese_edit);
    }
    await this.carregaEspecialidades();
    await this.carregaModelos();

    this.loaded = true;
  },
  watch: {
    solicitacao: {
      deep: true,
      handler() {
        if (this.loaded && this.solicitacao.id) {
          this.hasChanged = true;
        }
      },
    },
  },
};
</script>

<style></style>
