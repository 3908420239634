<template>
  <div>
    <div class="container-fluid">
      <div class="p-5 text-center" v-if="loading">
        <b-spinner /><br />
        aguarde...
      </div>
      <div class="text-right">
        <b-button variant="primary" @click="novo">
          <i class="fa fa-plus" /> Solicitar Anamnese
        </b-button>
      </div>

      <EmptyState v-if="anamneses.length == 0">
        <h3>
          Nenhuma anamnese encontrada!
        </h3>
        <p>
          Clique em
          <b-button class="mx-1" variant="primary" @click="novo">
            <i class="fa fa-plus" /> Solicitar Anamnese
          </b-button>
          para solicitar.
        </p>
      </EmptyState>

      <div v-if="!loading" class="listaItens">
        <paciente-item-card
          v-for="(anamnese, index) in anamneses"
          @openCard="editar(anamnese)"
          @preencherAnamnese="preencherAnamnese(anamnese)"
          :key="`anamneses_${anamnese.id}_index_${index}`"
          :objeto="anamnese"
          :tipo="`Anamnese`"
        />
      </div>

      <b-modal
        id="modal-add-anamnese"
        hide-footer
        size="sm"
        title="Solicitar Anamnese"
      >
        <paciente-anamnese-form
          @salvou="onSalvou"
          @close="close"
          :anamnese_edit="anamnese_edit"
          :paciente="paciente"
        ></paciente-anamnese-form>
      </b-modal>

      <b-modal id="modal-anamnese-card" hide-footer size="lg">
        <template #modal-title>
          <div><i class="fas fa-notes-medical"></i> Preencher Anamnese</div>
        </template>

        <anamnese-listagem
          @salvou="onAnamnese($event)"
          @close="closeAnamnese"
          :id="anamnese_preencher.anamnese_id"
          :paciente="anamnese_preencher.paciente"
          :questionarioPreenchido="anamnese_preencher.respostas"
          :responsavel="{
            nome: anamnese_preencher.usuario_anamnese,
            data: anamnese_preencher.dt_respondeu_anamnese,
          }"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import PacienteAnamneseForm from "./PacienteAnamneseForm.vue";
import PacienteAnamnesesLib from "../../../../libs/PacienteAnamnesesLib";
import AnamneseListagem from "../../../Anamnese/Listagem/AnamneseListagem.vue";
import PacienteItemCard from "../PacienteItemCard.vue";
import EmptyState from "../../../common/EmptyState.vue";
export default {
  name:"PacienteAnamnese",
  components: {
    PacienteAnamneseForm,
    PacienteItemCard,
    AnamneseListagem,
    EmptyState,
  },
  props: {
    paciente: Object,
  },
  mounted() {
    this.carregarAnamneses();
  },
  data() {
    return {
      loading: false,
      loadingSecundario: false,
      anamneses: [],
      anamnese_edit: {},
      anamnese_preencher: {
        respostas: {},
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    novo() {
      this.anamnese_edit = {};
      this.$bvModal.show("modal-add-anamnese");
    },
    async salvar(anamnese) {
      console.log("vai tentar salvar", anamnese);
      try {
        let result = await PacienteAnamnesesLib.store(anamnese);
        if (result.status == 201 || result.status == 200) {
          this.showToast("success", "Solicitação feita com sucesso!");
          await this.carregarAnamneses();
        }
      } catch (error) {
        console.log(error);
      }
    },
    onSalvou() {
      this.$bvModal.hide("modal-add-anamnese");
      this.carregarAnamneses();
    },
    editar(anamnese) {
      this.anamnese_edit = anamnese;
      this.$bvModal.show("modal-add-anamnese");
    },
    async carregarAnamneses() {
      try {
        this.anamneses = await PacienteAnamnesesLib.get({
          paciente_id: this.paciente.id,
        });
      } catch (error) {
        // this.showToast("error", "Erro ao carregar pacientes!");
      }
    },
    closeAnamnese() {
      this.$bvModal.hide("modal-anamnese-card");
    },
    close() {
      this.$bvModal.hide("modal-add-anamnese");
    },
    preencherAnamnese(anamnese) {
      this.anamnese_preencher = anamnese;
      this.$bvModal.show("modal-anamnese-card");
    },
    async onAnamnese(anamnese) {
      this.$forceUpdate();
      // console.log('recebeu anamnese?', anamnese);
      this.closeAnamnese();
      Object.assign(this.anamnese_preencher.respostas, anamnese);
      await this.salvar(this.anamnese_preencher);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/paciente.scss";
// .empty-state {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   background: rgba(0, 0, 0, 0.01);
//   font: "Poppins", sans-serif;

//   //box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.05);

//   img {
//     width: 18rem;
//     height: 20rem;
//     background: transparent;
//   }
// }
</style>
